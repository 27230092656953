@charset "utf-8";

//************************************
// layout files
//************************************

body{
  background: url( "../images/header_bg.jpg" ) top -60px center / 1602px auto no-repeat ;
  @include mq(lg) {
    background: url( "../images/header_bg.jpg" ) top center / 100% auto no-repeat;
  }
}

.l-header{
  // height: 350px;
  z-index: 100;
}

.l-contents{
  display: flex;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;

  .l-main{
    flex: 1;
    overflow-x: hidden;
  }

  .l-side{
    width: 180px;
    padding-left: 40px;
    @include mq(xs) {
      display: none;
    }
  }
}

.l-footer{
  background: url( "../images/footer_bg.jpg" ) top center / 1602px auto no-repeat;
  @include mq(lg) {
    background: url( "../images/footer_bg.jpg" ) top center / 100% auto no-repeat;
  }
}
