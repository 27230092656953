@charset "utf-8";

//************************************
// models files
//************************************

// 回転文字
.m-rotate_word {
  position: relative;
  display: block;
  width: 184px;
  height: 94px;

  > span[class^=w]:nth-of-type(n+0){
    display:block; position:absolute;
    transform-origin: 50% 100%;
  }

  span{
    font-size:9px;
    line-height: 0.65;
    white-space: pre;
    overflow: visible;
    padding:0px;
  }

  .w0 {
    transform: rotate(4.63rad);
    width: 10px;
    height: 6px;
    left: 31.16px;
    top: 67.25px;
  }

  .w1 {
    transform: rotate(4.81rad);
    width: 10px;
    height: 6px;
    left: 31.3px;
    top: 57.26px;
  }

  .w2 {
    transform: rotate(5rad);
    width: 10px;
    height: 6px;
    left: 33.27px;
    top: 47.47px;
  }

  .w3 {
    transform: rotate(5.18rad);
    width: 10px;
    height: 6px;
    left: 37.02px;
    top: 38.22px;
  }

  .w4 {
    transform: rotate(5.37rad);
    width: 10px;
    height: 6px;
    left: 42.4px;
    top: 29.8px;
  }

  .w5 {
    transform: rotate(5.55rad);
    width: 10px;
    height: 6px;
    left: 49.24px;
    top: 22.53px;
  }

  .w6 {
    transform: rotate(5.74rad);
    width: 10px;
    height: 6px;
    left: 57.3px;
    top: 16.64px;
  }

  .w7 {
    transform: rotate(5.92rad);
    width: 10px;
    height: 6px;
    left: 66.31px;
    top: 12.33px;
  }

  .w8 {
    transform: rotate(6.11rad);
    width: 10px;
    height: 6px;
    left: 75.96px;
    top: 9.76px;
  }

  .w9 {
    transform: rotate(6.3rad);
    width: 10px;
    height: 6px;
    left: 85.92px;
    top: 9px;
  }

  .w10 {
    transform: rotate(6.48rad);
    width: 10px;
    height: 6px;
    left: 95.84px;
    top: 10.1px;
  }

  .w11 {
    transform: rotate(6.67rad);
    width: 10px;
    height: 6px;
    left: 105.4px;
    top: 13px;
  }

  .w12 {
    transform: rotate(6.85rad);
    width: 10px;
    height: 6px;
    left: 114.25px;
    top: 17.61px;
  }

  .w13 {
    transform: rotate(7.04rad);
    width: 10px;
    height: 6px;
    left: 122.11px;
    top: 23.77px;
  }

  .w14 {
    transform: rotate(7.22rad);
    width: 10px;
    height: 6px;
    left: 128.7px;
    top: 31.28px;
  }

  .w15 {
    transform: rotate(7.41rad);
    width: 10px;
    height: 6px;
    left: 133.79px;
    top: 39.87px;
  }

  .w16 {
    transform: rotate(7.59rad);
    width: 10px;
    height: 6px;
    left: 137.22px;
    top: 49.25px;
  }

  .w17 {
    transform: rotate(7.78rad);
    width: 10px;
    height: 6px;
    left: 138.85px;
    top: 59.1px;
  }

  .w18 {
    transform: rotate(7.96rad);
    width: 10px;
    height: 6px;
    left: 138.65px;
    top: 69.08px;
  }

  .w19 {
    transform: rotate(8.15rad);
    width: 10px;
    height: 6px;
    left: 136.61px;
    top: 78.86px;
  }
}


// ボタン
.m-btn{
  background: red;
  display: inline-block;
  min-width: 200px;
  padding: 1em;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 0.1em;
  cursor: pointer;

  &.round{
    border-radius: 30px;
  }

  &.wide{
    width: 280px;
  }

  &.green{
    background-color: #58DD3C;
    border: solid 2px #58DD3C;
    &:hover{
      background-color: white;
      color: #58DD3C;
      font-weight: bold;
      .sp{
        color: #E36BA4;
      }
      .sm{
        color: #2E9119;
      }
      .au{
        color: #CC9933;
      }
    }
  }

  &.sky{
    background-color: #85DCE8;
    border: solid 2px #85DCE8;
    &:hover{
      background-color: white;
      color: #85DCE8;
      font-weight: bold;
      .wi{
        color: #106CC4;
      }
    }
  }

  &.grad{
     background: linear-gradient(270deg, #1DBAA6, #106CC4);
     color: white;
     font-size: 1rem;
     &:hover{
       opacity: 0.7;
     }
  }

  &.white{
    background: white;
    border: solid 1px black;
    color: black;
    &:hover{
      background: black;
      color: white;
    }
  }
}

a.m-language_btn{
  display: block;
  width: 86px;
  margin-top: 8px;
  padding: 2px 0;
  background: #1DBAA6;
  color: white;
  border: solid 2px #1DBAA6;
  font-size: .7rem;
  text-align: center;

  &:hover{
    background: white;
    color: #1DBAA6;
  }
}


.m-row_dl{
  &.p_t{
    padding-top: 1em;
  }
  >dt,dd{
    display: inline-block;
  }
  >dt{
    width: 8em;
    margin-right: 10px;
  }
  >dd{
    width: calc(100% - 8em - 10px);
  }
}

.m-row_table{
  .p-l{
    padding-left: 1em;
  }
}


.m-note{
  font-size: .8rem;
  margin-bottom: 5px;

  &-bold{
    @extend .m-note;
    font-weight: bold;
  }

  &-red{
    @extend .m-note;
    color: red;
  }
}
