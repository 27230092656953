@charset "UTF-8";
/* reset */
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* base */
body {
  width: 100%;
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  line-height: 1.4;
}

@media (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

a {
  text-decoration: none;
}

a:link {
  color: #000;
}

a:visited {
  color: #000;
}

a:hover {
  color: #777;
}

a:active {
  color: #777;
}

/* layout */
body {
  background: url("../images/header_bg.jpg") top -60px center/1602px auto no-repeat;
}

@media (min-width: 1200px) {
  body {
    background: url("../images/header_bg.jpg") top center/100% auto no-repeat;
  }
}

.l-header {
  z-index: 100;
}

.l-contents {
  display: flex;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

.l-contents .l-main {
  flex: 1;
  overflow-x: hidden;
}

.l-contents .l-side {
  width: 180px;
  padding-left: 40px;
}

@media (max-width: 767px) {
  .l-contents .l-side {
    display: none;
  }
}

.l-footer {
  background: url("../images/footer_bg.jpg") top center/1602px auto no-repeat;
}

@media (min-width: 1200px) {
  .l-footer {
    background: url("../images/footer_bg.jpg") top center/100% auto no-repeat;
  }
}

/* unit */
.u-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  max-width: 1024px;
  margin: auto;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .u-nav {
    justify-content: flex-end;
  }
}

.u-nav .logo {
  width: 129px;
  margin-right: auto;
  cursor: pointer;
}

.u-nav .logo img {
  width: 100%;
}

.u-nav #nav_toggle {
  display: none;
}

@media (max-width: 767px) {
  .u-nav #nav_toggle {
    display: block;
    min-width: 30px;
    height: 30px;
    position: relative;
    top: 4px;
    z-index: 100;
  }
  .u-nav #nav_toggle div {
    position: relative;
  }
  .u-nav #nav_toggle span {
    display: block;
    height: 3px;
    background: #2EBC19;
    position: absolute;
    width: 100%;
    left: 0;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .u-nav #nav_toggle span:nth-child(1) {
    top: 0px;
  }
  .u-nav #nav_toggle span:nth-child(2) {
    top: 10px;
  }
  .u-nav #nav_toggle span:nth-child(3) {
    top: 20px;
  }
  .u-nav #nav_toggle.open span {
    width: 110%;
  }
  .u-nav #nav_toggle.open span:nth-child(1) {
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .u-nav #nav_toggle.open span:nth-child(2) {
    width: 0;
    left: 50%;
  }
  .u-nav #nav_toggle.open span:nth-child(3) {
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}

.u-nav .nav {
  width: calc( 100vw - 129px - 86px - 20px);
  padding: 10px;
  flex: 1;
}

.u-nav .nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 767px) {
  .u-nav .nav {
    display: none;
  }
  .u-nav .nav ul {
    flex-direction: column;
    position: absolute;
    top: 80px;
    width: 200px;
    background: #fff;
    right: 10px;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
  .u-nav .nav ul .nav_item {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .u-nav .nav ul .nav_item a {
    line-height: 4em;
    display: block;
  }
}

.u-sns_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86px;
  padding-right: 20px;
  flex: 0 0 auto;
}

.u-ttlbar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: calc(1024px - 80px);
  height: 120px;
  padding: 0 20px;
  margin: auto;
  text-align: center;
}

.u-ttlbar .ttlbar_ttl {
  font-size: 1.3rem;
  font-weight: bold;
}

.u-ttlbar .ttlbar_ttl.tour {
  position: relative;
  margin-top: 40px;
  margin-left: 50px;
  padding: 10px 30px;
  background-image: linear-gradient(to right, black 10%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  letter-spacing: 0.2em;
}

.u-ttlbar .ttlbar_ttl.tour i {
  position: absolute;
  left: -70px;
  top: -20px;
}

@media (max-width: 767px) {
  .u-ttlbar .ttlbar_ttl span {
    display: block;
  }
}

.u-ttlbar.detail {
  align-items: flex-start;
  text-align: left;
}

.u-ttlbar.detail .ttlbar_ttl {
  padding-top: 30px;
}

.u-hero {
  padding-bottom: 30px;
  animation: rotate-anime 10s linear infinite;
  will-change: transform;
}

@keyframes rotate-anime {
  0% {
    transform: rotate(2deg);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(2deg);
  }
  75% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(2deg);
  }
}

.u-hero .hero_inner {
  position: relative;
  width: 100%;
  max-width: 750px;
  height: auto;
  margin: 0 auto;
  transform: scale(1.2, 1.2);
  transform-origin: center center;
}

.u-hero .hero_base {
  height: 100%;
  width: 100%;
  text-align: center;
}

.u-hero .hero_item {
  position: absolute;
  left: 0;
  top: 0;
  height: auto;
  cursor: pointer;
}

.u-hero .hero_item.bear {
  left: 26.8%;
  top: 28.5%;
  width: 17.4%;
}

.u-hero .hero_item.cruise {
  left: 55.5%;
  top: 49.1%;
  width: 25%;
}

.u-hero .hero_item.deer {
  left: 52.5%;
  top: 10.5%;
  width: 16.6%;
}

.u-hero .hero_item.fishingSquid {
  left: 52.5%;
  top: 68.5%;
  width: 19%;
}

.u-hero .hero_item.fishingUrchin {
  left: 21.2%;
  top: 57.1%;
  width: 19.6%;
}

.u-hero .hero_item.ichiba {
  left: 47.8%;
  top: 36.8%;
  width: 15%;
}

.u-hero .hero_item.ojiro {
  left: 33.4%;
  top: 69.3%;
  width: 18.6%;
}

.u-hero .hero_item.orca {
  left: 63.4%;
  top: 59.9%;
  width: 16.4%;
}

.u-hero .hero_item.owashi {
  left: 10.2%;
  top: 68.9%;
  width: 23.5%;
}

.u-hero .hero_item.owl {
  left: 43.1%;
  top: 23%;
  width: 10.6%;
}

.u-hero .hero_item.salmon {
  left: 40%;
  top: 38.3%;
  width: 12%;
}

.u-hero .hero_item.small {
  left: 16.1%;
  top: 40.3%;
  width: 21.8%;
}

.u-hero .hero_item.whale {
  left: 41.8%;
  top: 58.3%;
  width: 24%;
}

.u-card {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;
  padding-top: 40px;
}

.u-card .card_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .u-card .card_item {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .u-card .card_item {
    width: 100%;
  }
}

.u-card .card_ttl {
  position: relative;
  height: 60px;
  margin-bottom: -20px;
  font-size: 1.15rem;
  font-weight: bold;
}

.u-card .card_ttl.mb_no {
  transform: translateY(-20px);
}

.u-card .card_ttl.em-5 {
  width: 5em;
}

.u-card .card_ttl.em-6 {
  width: 6em;
}

.u-card .card_ttl.em-7 {
  width: 7em;
}

.u-card .card_ttl.em-8 {
  width: 8em;
}

.u-card .card_ttl.em-9 {
  width: 9em;
}

.u-card .card_ttl.em-10 {
  width: 10em;
}

.u-card .card_ttl.em-11 {
  width: 11em;
}

.u-card .card_ttl.em-12 {
  width: 12em;
}

.u-card .card_ttl.em-13 {
  width: 13em;
}

.u-card .card_ttl .m-rotate_word {
  position: absolute;
}

.u-card .card_ttl .m-rotate_word.left {
  left: -60px;
  top: -35px;
}

.u-card .card_ttl .m-rotate_word.right {
  right: -60px;
  top: -35px;
}

.u-card .card_ttl .main_ttl span {
  display: block;
}

.u-card .card_ttl .main_ttl .left {
  text-align: left;
}

.u-card .card_ttl .main_ttl .right {
  text-align: right;
}

.u-card .card_ttl .sub_ttl {
  font-size: .8rem;
  margin-top: 8px;
}

.u-card .card_img {
  width: 100%;
  max-width: 320px;
  min-height: 50px;
  height: auto;
}

.u-card .card_txt {
  width: 90%;
  max-width: 250px;
  font-size: .85rem;
  font-weight: bold;
}

.u-card .card_info {
  display: flex;
  width: 90%;
  max-width: 250px;
  padding-top: 1em;
  font-size: .85rem;
  font-weight: bold;
}

.u-card .card_info > dt {
  padding-right: 10px;
}

.u-card .card_info .ind {
  text-indent: 1em;
}

.u-card .card_info .ast {
  text-indent: -1em;
  margin-left: 1em;
}

.u-card .card_link {
  position: absolute;
  height: 100%;
  width: 100%;
}

.u-card .card_link:hover {
  background: rgba(255, 255, 255, 0.3);
}

.u-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.u-links .links_txt {
  padding-bottom: 10px;
  letter-spacing: 0.2em;
}

.u-links .links_page, .u-links .pdf_link {
  padding-bottom: 50px;
}

.u-links .links_page a, .u-links .pdf_link a {
  margin: 0 5px;
}

@media (max-width: 767px) {
  .u-links .links_page, .u-links .pdf_link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .u-links .links_page a, .u-links .pdf_link a {
    margin-bottom: 10px;
  }
}

.u-links .u-phone {
  border: solid black 1px;
  padding: 20px 30px;
}

.u-links .u-phone .phone-ttl {
  margin-bottom: 1em;
  text-align: center;
  color: #1DBAA6;
}

.u-links .pdf_txt {
  padding-bottom: 10px;
  font-size: 0.9em;
  text-align: center;
}

.u-links .pdf_ttl {
  padding-bottom: 10px;
  font-size: 1.3em;
  text-align: center;
}

.u-links .pdf-outline {
  max-width: 800px;
  padding: 20px;
  margin: 0 20px 40px 20px;
  border: solid #E50012 1px;
}

.u-links .pdf-outline .pdf_txt {
  color: #E50012;
  text-align: left;
  margin-bottom: 5px;
}

.u-links .pdf-outline .pdf_ttl {
  color: #E50012;
}

.u-links .pdf-outline .pdf_link {
  padding-bottom: 0;
  text-align: center;
}

.u-tour_link .tour_link_item {
  position: relative;
  background-image: linear-gradient(to right, black 20%, rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  color: #fff;
}

.u-tour_link .tour_link_item:first-child::before {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  position: relative;
  background-image: linear-gradient(to right, black 20%, rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  color: #fff;
}

.u-tour_link .tour_link_item a {
  display: block;
  font-size: .9rem;
  padding: 1em 0;
}

.u-tourview .tour_img {
  width: 100%;
  height: auto;
}

/*タブ切り替え全体のスタイル*/
.u-tab {
  width: 100%;
  margin: 30px 0;
  padding: 40px 0;
  background-color: #fff;
}

.u-tab .tab_item {
  display: block;
  float: left;
  width: calc((100% / 5) - 12px);
  height: 25px;
  margin: 5px 0;
  padding: 0 5px;
  line-height: 25px;
  text-align: center;
  text-align: center;
  transition: all 0.2s ease;
}

@media (max-width: 767px) {
  .u-tab .tab_item {
    font-size: .8em;
  }
}

.u-tab .tab_item:hover {
  opacity: 0.75;
}

.u-tab input:checked + .tab_item {
  background-color: #1DBAA6;
  color: white;
  opacity: 1;
}

.u-tab input[name="tab_item"] {
  display: none;
}

.u-tab .tab_content {
  display: none;
  border-top: 2px solid black;
  padding: 30px 10px;
  clear: both;
}

.u-tab .tab_content .tab_txt p {
  margin-bottom: 2em;
}

.u-tab .tab_content .tab_txt .ind {
  margin-left: 1.5em;
}

.u-tab .tab_content .tab_txt .line {
  margin-bottom: 20px;
  border-bottom: solid 1px black;
}

.u-tab .tab_content .tab_txt .cent {
  text-align: center;
}

.u-tab .tab_content .tab_txt .map_01 {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.u-tab .tab_content .tab_txt .map_02 {
  max-width: 370px;
  width: 100%;
  height: auto;
}

.u-tab .tab_content .tab_txt .outline {
  display: inline-block;
  padding: 2px 10px;
  border: solid 2px black;
  border-radius: 15px;
}

.u-tab .tab_content .tab_txt .outline.red {
  border: solid 2px #E50012;
  color: #E50012;
}

.u-tab .tab_content .tab_txt .point {
  padding-left: .5em;
  font-size: .9rem;
}

.u-tab .tab_content .tab_txt .point.red {
  color: #E50012;
}

.u-tab .tab_content .tab_txt h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.u-tab .tab_content .tab_txt h5 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.u-tab .tab_content .tab_txt li {
  margin: 5px 0;
  font-weight: bold;
}

.u-tab .tab_content .tab_txt .txt_red {
  color: #E50012;
}

.u-tab .tab_content .access_link {
  padding: 20px 30px;
  background: #eee;
}

.u-tab .tab_content .access_link .access_ttl {
  font-weight: bold;
  padding-bottom: 10px;
}

.u-tab .tab_content .access_link .access_txt {
  margin-left: 1em;
}

.u-tab .tab_content .access_link .access_txt a {
  text-decoration: underline;
}

.u-tab .tab_content .tab_info {
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
}

.u-tab .tab_content .tab_info > dt {
  display: flex;
  align-items: center;
  width: 10em;
  font-size: 1rem;
}

.u-tab .tab_content .tab_info > dt i {
  margin-right: 10px;
}

.u-tab .tab_content .tab_info > dt.no-icon {
  text-indent: 34px;
}

.u-tab .tab_content .tab_info > dd {
  width: 100%;
  font-weight: bold;
}

.u-tab .tab_content .tab_info > dd > p {
  margin-bottom: 5px;
}

.u-tab .tab_content .tab_info > dd .half_ind {
  text-indent: -0.5em;
}

.u-tab .tab_content .tab_info.column {
  flex-direction: column;
}

.u-tab .tab_content .tab_info.column > dt {
  width: 100%;
  padding-bottom: 20px;
}

.u-tab .tab_content .tab_info.column .gray {
  width: calc(100% - 40px);
  max-width: 500px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.03);
  font-size: 1.1rem;
}

.u-tab .tab_content .belongings_book {
  background: white;
  padding: 30px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.u-tab .tab_content .belongings_book .belongings_ttl {
  max-width: 500px;
  width: 100%;
  margin: auto;
}

.u-tab .tab_content .belongings_book .belongings_ttl img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .u-tab .tab_content .belongings_book .belongings_ttl {
    max-width: 354px;
    width: 100%;
    margin: auto;
  }
  .u-tab .tab_content .belongings_book .belongings_ttl img {
    width: 100%;
    height: auto;
  }
}

.u-tab .tab_content .belongings_book .belongings_sub {
  text-align: center;
  padding-top: 5px;
}

.u-tab .tab_content .belongings_book .contents_ttl {
  font-size: 1.2rem;
  text-decoration: underline;
  padding-bottom: 8px;
}

.u-tab .tab_content .belongings_book .contents_group {
  position: relative;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .u-tab .tab_content .belongings_book .contents_group {
    flex-direction: column;
    align-items: center;
  }
}

.u-tab .tab_content .belongings_book .contents_read {
  flex: 1;
}

.u-tab .tab_content .belongings_book .contents_read .attention {
  color: #EC1B23;
}

.u-tab .tab_content .belongings_book .contents_img {
  height: 100%;
  padding-left: 20px;
  padding-bottom: 10px;
}

.u-tab .tab_content .belongings_book .contents_img-kuma {
  position: absolute;
  height: auto;
  right: 0;
  bottom: -15px;
  z-index: 2;
}

.u-tab .tab_content .belongings_book .contents_list {
  padding-bottom: 20px;
  min-width: 300px;
}

.u-tab .tab_content .belongings_book .contents_list > dt {
  display: flex;
  padding-bottom: 10px;
}

.u-tab .tab_content .belongings_book .contents_list > dt > i {
  margin-right: 10px;
}

.u-tab .tab_content .belongings_book .contents_list > dd {
  text-indent: -1em;
  padding-left: 2em;
}

.u-tab .tab_content .belongings_book .contents_list > dd:before {
  content: "□";
}

.u-timesch {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.u-timesch .timesch_item {
  width: calc((100% / 3) - 20px);
  padding: 0 10px 20px 10px;
}

@media (max-width: 767px) {
  .u-timesch .timesch_item {
    width: calc((100% / 2) - 20px);
  }
}

@media (max-width: 479px) {
  .u-timesch .timesch_item {
    width: 90%;
  }
}

.u-timesch .timesch_img {
  position: relative;
  border-radius: 5px;
  margin-bottom: 10px;
}

.u-timesch .timesch_img:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.u-timesch .timesch_img img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: bottom;';
}

.u-timesch .timesch_ttl {
  position: relative;
  min-height: 50px;
  margin: 10px 0;
  padding-bottom: .5em;
  background-image: linear-gradient(to right, black 20%, rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  font-size: 1rem;
}

.u-timesch .timesch_ttl:first-child::before {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  margin-bottom: .5em;
  position: relative;
  background-image: linear-gradient(to right, black 20%, rgba(0, 0, 0, 0) 0%);
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
  color: #fff;
}

.u-timesch .timesch_ttl span {
  display: block;
  font-size: .8rem;
  text-align: right;
}

.u-timesch .timesch_txt {
  font-size: .75rem;
}

.u_lincle_rule {
  background: white;
  padding: 30px;
  border: solid 4px black;
}

.u_lincle_rule .lincle_rule_ttl {
  height: 40px;
  width: auto;
  padding-bottom: 30px;
  text-align: center;
}

.u_lincle_rule .lincle_rule_ttl img {
  height: 100%;
  width: auto;
}

.u_lincle_rule .lincle_rule_sub {
  font-size: .9rem;
}

.u_lincle_rule .rule_cat {
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 30px;
}

.u_lincle_rule .rule_cat i {
  margin-right: 10px;
}

.u_lincle_rule .rule_list {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.u_lincle_rule .rule_list .rule_item {
  width: 31%;
  height: 100%;
}

.u_lincle_rule .rule_list .rule_item-last {
  width: 60%;
  height: 100%;
}

@media (max-width: 767px) {
  .u_lincle_rule .rule_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
  .u_lincle_rule .rule_list .rule_item {
    width: 100%;
    max-width: 300px;
    height: auto;
    padding-top: 20px;
  }
  .u_lincle_rule .rule_list .rule_item-last {
    width: 130%;
    max-width: 600px;
    height: auto;
    padding-top: 20px;
  }
}

#course:checked ~ #course_content,
#price:checked ~ #price_content,
#participation:checked ~ #participation_content,
#belongings:checked ~ #belongings_content,
#access:checked ~ #access_content {
  display: block;
}

.u-message {
  max-width: 500px;
  width: 100%;
  height: auto;
  margin: 30px auto 100px auto;
  background: url("../images/bg_logo.png") center center/100% auto no-repeat;
  text-align: center;
}

.u-message > p {
  line-height: 2em;
}

.u-message > p > span {
  display: block;
}

.u-stafflist .stafflist_ttl {
  margin-bottom: 70px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.u-staff {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: 50px;
}

.u-staff .staff_item {
  width: calc((100% / 3) - 50px);
  padding: 0 25px 20px 25px;
}

@media (max-width: 767px) {
  .u-staff .staff_item {
    width: calc((100% / 2) - 50px);
  }
}

@media (max-width: 479px) {
  .u-staff .staff_item {
    width: 90%;
  }
}

.u-staff .staff_img {
  position: relative;
  border-radius: 50%;
}

.u-staff .staff_img:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.u-staff .staff_img img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.u-staff .staff_name {
  position: relative;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.u-staff .staff_sub {
  position: relative;
  margin-bottom: 20px;
  font-size: .9rem;
  text-align: center;
}

.u-staff .staff_txt {
  padding: 0 20px;
  margin-bottom: 30px;
  font-size: .85rem;
}

.u-form {
  max-width: 700px;
  margin: 20px auto;
  padding: 10px;
  padding-bottom: 150px;
}

.u-form .form_table {
  width: calc(100% - 20px);
}

.u-form .form_item {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 16px 0;
}

.u-form .form_item.row {
  flex-direction: row;
  justify-content: center;
}

.u-form .form_item .item_ttl {
  margin-bottom: .5em;
}

.u-form .form_item button {
  margin: 10px;
}

.u-form a.link {
  color: #1DBAA6 　 !important;
  text-decoration: underline;
}

.u-form p.error_messe {
  margin-top: 20px;
}

.u-form .center {
  text-align: center;
}

.u-form .green {
  color: #1DBAA6;
}

.formTable {
  width: 100%;
  margin: 40px 0 20px 0;
}

.formTable th {
  width: 9em;
  padding: 10px 0;
  color: gray;
}

.u-footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 300px;
  max-width: 650px;
  margin: auto;
}

@media (min-width: 1200px) {
  .u-footer {
    height: 300px;
  }
}

.u-footer .u-sns_action {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 210px;
  width: 190px;
}

.u-footer .footer_info {
  display: flex;
  align-items: center;
}

.u-footer .footer_info .logo {
  width: 110px;
  height: 30px;
  padding: 10px;
}

.u-footer .footer_info .logo img {
  width: 100%;
  height: auto;
}

.u-footer .footer_info .info_txt > dt {
  font-size: 0.75em;
}

.u-footer .footer_info .info_txt > dd {
  font-size: 0.6em;
  line-height: 1;
}

.u-footer .copy {
  margin-bottom: 10px;
  font-size: 0.7em;
  color: white;
  letter-spacing: 0.1em;
  text-align: center;
}

/* model */
.m-rotate_word {
  position: relative;
  display: block;
  width: 184px;
  height: 94px;
}

.m-rotate_word > span[class^=w]:nth-of-type(n+0) {
  display: block;
  position: absolute;
  transform-origin: 50% 100%;
}

.m-rotate_word span {
  font-size: 9px;
  line-height: 0.65;
  white-space: pre;
  overflow: visible;
  padding: 0px;
}

.m-rotate_word .w0 {
  transform: rotate(4.63rad);
  width: 10px;
  height: 6px;
  left: 31.16px;
  top: 67.25px;
}

.m-rotate_word .w1 {
  transform: rotate(4.81rad);
  width: 10px;
  height: 6px;
  left: 31.3px;
  top: 57.26px;
}

.m-rotate_word .w2 {
  transform: rotate(5rad);
  width: 10px;
  height: 6px;
  left: 33.27px;
  top: 47.47px;
}

.m-rotate_word .w3 {
  transform: rotate(5.18rad);
  width: 10px;
  height: 6px;
  left: 37.02px;
  top: 38.22px;
}

.m-rotate_word .w4 {
  transform: rotate(5.37rad);
  width: 10px;
  height: 6px;
  left: 42.4px;
  top: 29.8px;
}

.m-rotate_word .w5 {
  transform: rotate(5.55rad);
  width: 10px;
  height: 6px;
  left: 49.24px;
  top: 22.53px;
}

.m-rotate_word .w6 {
  transform: rotate(5.74rad);
  width: 10px;
  height: 6px;
  left: 57.3px;
  top: 16.64px;
}

.m-rotate_word .w7 {
  transform: rotate(5.92rad);
  width: 10px;
  height: 6px;
  left: 66.31px;
  top: 12.33px;
}

.m-rotate_word .w8 {
  transform: rotate(6.11rad);
  width: 10px;
  height: 6px;
  left: 75.96px;
  top: 9.76px;
}

.m-rotate_word .w9 {
  transform: rotate(6.3rad);
  width: 10px;
  height: 6px;
  left: 85.92px;
  top: 9px;
}

.m-rotate_word .w10 {
  transform: rotate(6.48rad);
  width: 10px;
  height: 6px;
  left: 95.84px;
  top: 10.1px;
}

.m-rotate_word .w11 {
  transform: rotate(6.67rad);
  width: 10px;
  height: 6px;
  left: 105.4px;
  top: 13px;
}

.m-rotate_word .w12 {
  transform: rotate(6.85rad);
  width: 10px;
  height: 6px;
  left: 114.25px;
  top: 17.61px;
}

.m-rotate_word .w13 {
  transform: rotate(7.04rad);
  width: 10px;
  height: 6px;
  left: 122.11px;
  top: 23.77px;
}

.m-rotate_word .w14 {
  transform: rotate(7.22rad);
  width: 10px;
  height: 6px;
  left: 128.7px;
  top: 31.28px;
}

.m-rotate_word .w15 {
  transform: rotate(7.41rad);
  width: 10px;
  height: 6px;
  left: 133.79px;
  top: 39.87px;
}

.m-rotate_word .w16 {
  transform: rotate(7.59rad);
  width: 10px;
  height: 6px;
  left: 137.22px;
  top: 49.25px;
}

.m-rotate_word .w17 {
  transform: rotate(7.78rad);
  width: 10px;
  height: 6px;
  left: 138.85px;
  top: 59.1px;
}

.m-rotate_word .w18 {
  transform: rotate(7.96rad);
  width: 10px;
  height: 6px;
  left: 138.65px;
  top: 69.08px;
}

.m-rotate_word .w19 {
  transform: rotate(8.15rad);
  width: 10px;
  height: 6px;
  left: 136.61px;
  top: 78.86px;
}

.m-btn {
  background: red;
  display: inline-block;
  min-width: 200px;
  padding: 1em;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 0.1em;
  cursor: pointer;
}

.m-btn.round {
  border-radius: 30px;
}

.m-btn.wide {
  width: 280px;
}

.m-btn.green {
  background-color: #58DD3C;
  border: solid 2px #58DD3C;
}

.m-btn.green:hover {
  background-color: white;
  color: #58DD3C;
  font-weight: bold;
}

.m-btn.green:hover .sp {
  color: #E36BA4;
}

.m-btn.green:hover .sm {
  color: #2E9119;
}

.m-btn.green:hover .au {
  color: #CC9933;
}

.m-btn.sky {
  background-color: #85DCE8;
  border: solid 2px #85DCE8;
}

.m-btn.sky:hover {
  background-color: white;
  color: #85DCE8;
  font-weight: bold;
}

.m-btn.sky:hover .wi {
  color: #106CC4;
}

.m-btn.grad {
  background: linear-gradient(270deg, #1DBAA6, #106CC4);
  color: white;
  font-size: 1rem;
}

.m-btn.grad:hover {
  opacity: 0.7;
}

.m-btn.white {
  background: white;
  border: solid 1px black;
  color: black;
}

.m-btn.white:hover {
  background: black;
  color: white;
}

a.m-language_btn {
  display: block;
  width: 86px;
  margin-top: 8px;
  padding: 2px 0;
  background: #1DBAA6;
  color: white;
  border: solid 2px #1DBAA6;
  font-size: .7rem;
  text-align: center;
}

a.m-language_btn:hover {
  background: white;
  color: #1DBAA6;
}

.m-row_dl.p_t {
  padding-top: 1em;
}

.m-row_dl > dt, .m-row_dl dd {
  display: inline-block;
}

.m-row_dl > dt {
  width: 8em;
  margin-right: 10px;
}

.m-row_dl > dd {
  width: calc(100% - 8em - 10px);
}

.m-row_table .p-l {
  padding-left: 1em;
}

.m-note, .m-note-bold, .m-note-red {
  font-size: .8rem;
  margin-bottom: 5px;
}

.m-note-bold {
  font-weight: bold;
}

.m-note-red {
  color: red;
}

/* icon */
.icon_fb, .icon_ins, .icon_tw, .icon_tour, .icon_tour_win, .icon_day, .icon_time, .icon_guid, .icon_f_car, .icon_f_garm, .icon_f_day, .icon_f_guid, .icon_f_meetplace, .icon_f_meettime, .icon_f_money, .icon_f_time, .icon_f_timesch, .icon_rule_01, .icon_rule_02, .icon_rule_03, .icon_belongings {
  display: block;
  padding: 0;
  margin: 0;
}

.icon_fb {
  background: url("../images/icon_fb.png") center/100% no-repeat;
  height: 22px;
  width: 22px;
}

.icon_ins {
  background: url("../images/icon_ins.png") center/100% no-repeat;
  height: 22px;
  width: 22px;
}

.icon_tw {
  background: url("../images/icon_tw.png") center/100% no-repeat;
  height: 22px;
  width: 22px;
}

.icon_tour {
  background: url("../images/icon_tour.png") center/100% no-repeat;
  height: 80px;
  width: 80px;
}

.icon_tour_win {
  background: url("../images/icon_tour_win.png") center/100% no-repeat;
  height: 80px;
  width: 80px;
}

.icon_day {
  background: url("../images/icon_day.png") center/100% no-repeat;
  height: 18px;
  width: 18px;
}

.icon_time {
  background: url("../images/icon_time.png") center/100% no-repeat;
  height: 18px;
  width: 18px;
}

.icon_guid {
  background: url("../images/icon_guid.png") center/100% no-repeat;
  height: 18px;
  width: 18px;
}

.icon_f_car {
  background: url("../images/icon_f_car.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_garm {
  background: url("../images/icon_f_garm.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_day {
  background: url("../images/icon_f_day.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_guid {
  background: url("../images/icon_f_guid.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_meetplace {
  background: url("../images/icon_f_meetplace.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_meettime {
  background: url("../images/icon_f_meettime.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_money {
  background: url("../images/icon_f_money.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_time {
  background: url("../images/icon_f_time.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_f_timesch {
  background: url("../images/icon_f_timesch.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_rule_01 {
  background: url("../images/tour/rule_icon_01.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_rule_02 {
  background: url("../images/tour/rule_icon_02.png") center/100% no-repeat;
  height: 24px;
  width: 32px;
}

.icon_rule_03 {
  background: url("../images/tour/rule_icon_03.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

.icon_belongings {
  background: url("../images/tour/belongings_icon.png") center/100% no-repeat;
  height: 24px;
  width: 24px;
}

/* input */
input[type=text], button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=text], textarea {
  width: 100%;
  margin: auto;
  padding: 10px;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  color: #777;
  border: solid 1px #ccc;
  border-radius: 3px;
  box-shadow: inner 0 0 4px rgba(0, 0, 0, 0.2);
}

input[type=text]:focus, textarea:focus {
  border: solid 1px #1DBAA6;
}

textarea {
  resize: vertical;
  min-height: 6em;
}

.btn_submit, .btn_reset {
  height: 40px;
  min-width: 100px;
  padding: 10px;
  font-size: .8rem;
}

.btn_submit {
  border: solid 1px #1DBAA6;
  color: #1DBAA6;
}

.btn_submit:hover {
  background: #1DBAA6;
  color: white;
}

.btn_reset {
  border: solid 1px #aaa;
  color: #aaa;
}

.btn_reset:hover {
  background: #aaa;
  color: white;
}

/* plugins */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
