@charset "utf-8";

//************************************
// icon files
//************************************

%icon{
  display: block;
  padding: 0;
  margin: 0;
}

.icon_fb{
  @extend %icon;
  background: url( "../images/icon_fb.png" ) center / 100% no-repeat ;
  height: 22px;
  width: 22px;
}

.icon_ins{
  @extend %icon;
  background: url( "../images/icon_ins.png" ) center / 100% no-repeat ;
  height: 22px;
  width: 22px;
}

.icon_tw{
  @extend %icon;
  background: url( "../images/icon_tw.png" ) center / 100% no-repeat ;
  height: 22px;
  width: 22px;
}

.icon_tour{
  @extend %icon;
  background: url( "../images/icon_tour.png" ) center / 100% no-repeat ;
  height: 80px;
  width: 80px;
}

.icon_tour_win{
  @extend %icon;
  background: url( "../images/icon_tour_win.png" ) center / 100% no-repeat ;
  height: 80px;
  width: 80px;
}

.icon_day{
  @extend %icon;
  background: url( "../images/icon_day.png" ) center / 100% no-repeat ;
  height: 18px;
  width: 18px;
}

.icon_time{
  @extend %icon;
  background: url( "../images/icon_time.png" ) center / 100% no-repeat ;
  height: 18px;
  width: 18px;
}

.icon_guid{
  @extend %icon;
  background: url( "../images/icon_guid.png" ) center / 100% no-repeat ;
  height: 18px;
  width: 18px;
}

.icon_f_car{
  @extend %icon;
  background: url( "../images/icon_f_car.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_garm{
  @extend %icon;
  background: url( "../images/icon_f_garm.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_day{
  @extend %icon;
  background: url( "../images/icon_f_day.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_guid{
  @extend %icon;
  background: url( "../images/icon_f_guid.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_meetplace{
  @extend %icon;
  background: url( "../images/icon_f_meetplace.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_meettime{
  @extend %icon;
  background: url( "../images/icon_f_meettime.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_money{
  @extend %icon;
  background: url( "../images/icon_f_money.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_time{
  @extend %icon;
  background: url( "../images/icon_f_time.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_f_timesch{
  @extend %icon;
  background: url( "../images/icon_f_timesch.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_rule_01{
  @extend %icon;
  background: url( "../images/tour/rule_icon_01.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_rule_02{
  @extend %icon;
  background: url( "../images/tour/rule_icon_02.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 32px;
}

.icon_rule_03{
  @extend %icon;
  background: url( "../images/tour/rule_icon_03.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}

.icon_belongings{
  @extend %icon;
  background: url( "../images/tour/belongings_icon.png" ) center / 100% no-repeat ;
  height: 24px;
  width: 24px;
}
