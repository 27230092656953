@charset "utf-8";

//************************************
// unit files
//************************************

.u-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  max-width: 1024px;
  margin: auto;
  padding: 0 10px;

  @include mq(xs) {
    justify-content: flex-end;
  }

  .logo{
    width: 129px;
    margin-right: auto;
    cursor: pointer;
    img{
      width: 100%;
    }
  }

  #nav_toggle{
  	display: none;

    @include mq(xs) {
      display: block;
  		min-width: 30px;
  		height: 30px;
  		position: relative;
  		top: 4px;
  		z-index: 100;

      div {
    		position: relative;
    	}

      span{
    		display: block;
    		height: 3px;
    		background: #2EBC19;
    		position:absolute;
    		width: 100%;
    		left: 0;
    		-webkit-transition: 0.5s ease-in-out;
    		-moz-transition: 0.5s ease-in-out;
    		transition: 0.5s ease-in-out;

        &:nth-child(1){
      		top:0px;
      	}

        &:nth-child(2){
      		top:10px;
      	}

        &:nth-child(3){
      		top:20px;
      	}
      }

      &.open span{
        width: 110%;

        &:nth-child(1){
          top: 10px;
    		   -webkit-transform: rotate(135deg);
    			-moz-transform: rotate(135deg);
    			transform: rotate(135deg);
      	}

        &:nth-child(2){
          width: 0;
    			left: 50%;
      	}

        &:nth-child(3){
          top: 10px;
    			-webkit-transform: rotate(-135deg);
    			-moz-transform: rotate(-135deg);
    			transform: rotate(-135deg);
      	}
      }
    }
  }

  .nav{
    width: calc( 100vw - 129px - 86px - 20px);
    padding: 10px;
    flex: 1;

    ul{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    @include mq(xs) {
      display: none;

      ul{
        flex-direction: column;
    		position: absolute;
    		top: 80px;
    		width: 200px;
    		background: #fff;
    		right: 10px;
        padding: 1em;
        border-radius: 5px;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
        z-index: 100;

        .nav_item{
          width: 100%;
      		margin: 0 auto;
      		text-align: center;

          a{
            line-height: 4em;
        		display: block;
        	}
        }
      }
    }
  }
}

.u-sns_links{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86px;
  padding-right: 20px;
  flex: 0 0 auto;

  .sns_links_item{
  }
}

.u-ttlbar{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: calc(1024px - 80px);
  height: 120px;
  padding: 0 20px;
  margin: auto;
  text-align: center;

  .ttlbar_ttl{
    font-size: 1.3rem;
    font-weight: bold;

    &.tour{
      position: relative;
      margin-top: 40px;
      margin-left: 50px;
      padding: 10px 30px;
      background-image: linear-gradient(to right, black 10%, rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
      letter-spacing: 0.2em;
      i{
        position: absolute;
        left: -70px;
        top: -20px;
      }
    }

    span{
      @include mq(xs) {
        display: block;
      }
    }
  }

  &.detail{
    align-items: flex-start;
    text-align: left;
    .ttlbar_ttl{
      padding-top: 30px;
    }
  }

  .ttlbar_txt{
  }
}

.u-hero{
  // margin-top: -3vw;
  padding-bottom: 30px;
  animation: rotate-anime 10s linear infinite;
  will-change: transform;

  @keyframes rotate-anime {
    0%  {transform: rotate(2deg);}
    25%  {transform: rotate(4deg);}
    50%  {transform: rotate(2deg);}
    75%  {transform: rotate(0);}
    100%  {transform: rotate(2deg);}
  }

  .hero_inner{
    position: relative;
    width: 100%;
    max-width: 750px;
    height: auto;
    margin: 0 auto;
    transform: scale(1.2, 1.2);
    transform-origin: center center;
  }
  .hero_base{
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .hero_item{
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    cursor: pointer;

    &.bear{
      left: 26.8%;
      top: 28.5%;
      width: 17.4%;
    }

    &.cruise{
      left: 55.5%;
      top: 49.1%;
      width: 25%;
    }

    &.deer{
      left: 52.5%;
      top: 10.5%;
      width: 16.6%;
    }

    &.fishingSquid{
      left: 52.5%;
      top: 68.5%;
      width: 19%;
    }

    &.fishingUrchin{
      left: 21.2%;
      top: 57.1%;
      width: 19.6%;
    }

    &.ichiba{
      left: 47.8%;
      top: 36.8%;
      width: 15%;
    }

    &.ojiro{
      left: 33.4%;
      top: 69.3%;
      width: 18.6%;
    }

    &.orca{
      left: 63.4%;
      top: 59.9%;
      width: 16.4%;
    }

    &.owashi{
      left: 10.2%;
      top: 68.9%;
      width: 23.5%;
    }

    &.owl{
      left: 43.1%;
      top: 23%;
      width: 10.6%;
    }

    &.salmon{
      left: 40%;
      top: 38.3%;
      width: 12%;
    }

    &.small{
      left: 16.1%;
      top: 40.3%;
      width: 21.8%;
    }

    &.whale{
      left: 41.8%;
      top: 58.3%;
      width: 24%;
    }

  }
  // itemは％で指定予定。うまくいくかな？
}

.u-card{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 auto;
  padding-top: 40px;

  .card_item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    margin-bottom: 100px;

    @include mq(xs) {
      width: 50%;
    }

    @include mq(xxs) {
      width: 100%;
    }
  }
  .card_ttl{
    position: relative;
    height: 60px;
    margin-bottom: -20px;
    font-size: 1.15rem;
    font-weight: bold;

    &.mb_no{
      transform: translateY(-20px);
    }

    &.em-5 { width: 5em;}
    &.em-6 { width: 6em;}
    &.em-7 { width: 7em;}
    &.em-8 { width: 8em;}
    &.em-9 { width: 9em;}
    &.em-10{ width: 10em;}
    &.em-11{ width: 11em;}
    &.em-12{ width: 12em;}
    &.em-13{ width: 13em;}

    .m-rotate_word{
      position: absolute;

      &.left{
        left: -60px;
        top: -35px;
      }

      &.right{
        right: -60px;
        top: -35px;
      }
    }

    .main_ttl{
      span{
      display:block;
      }
      .left{
        text-align: left;
      }
      .right{
        text-align: right;
      }
    }

    .sub_ttl{
      font-size: .8rem;
      margin-top: 8px;
    }
  }
  .card_img{
    width: 100%;
    max-width: 320px;
    min-height: 50px;
    height: auto;
  }
  .card_txt{
    width: 90%;
    max-width: 250px;
    font-size: .85rem;
    font-weight: bold;
  }
  .card_info{
    display: flex;
    width: 90%;
    max-width: 250px;
    padding-top: 1em;
    font-size: .85rem;
    font-weight: bold;
    > dt{
      padding-right: 10px;
    }
    .ind{
      text-indent: 1em;
    }
    .ast{
      text-indent: -1em;
      margin-left: 1em;
    }
  }
  .card_link{
    position: absolute;
    height: 100%;
    width: 100%;
    &:hover{
      background: rgba(255,255,255,0.3);
    }
  }
}

.u-links{
  display: flex;
  flex-direction: column;
  align-items: center;
  .links_txt{
    padding-bottom: 10px;
    letter-spacing: 0.2em;
  }
  .links_page, .pdf_link{
    padding-bottom: 50px;
    a{
      margin: 0 5px;
    }
    @include mq(xs) {
      display: flex;
      flex-direction: column;
      align-items: center;
      a{
        margin-bottom: 10px;
      }
    }
  }
  .links_reserv{
  }

  .u-phone{
    border: solid black 1px;
    padding: 20px 30px;
    .phone-ttl{
      margin-bottom: 1em;
      text-align: center;
      color: #1DBAA6;
    }
    .phone-txt{

    }
  }

  .pdf_txt{
    padding-bottom: 10px;
    font-size: 0.9em;
    text-align: center;
  }

  .pdf_ttl{
    padding-bottom: 10px;
    font-size: 1.3em;
    text-align: center;
  }

  .pdf-outline{
    max-width: 800px;
    padding: 20px;
    margin: 0 20px 40px 20px;
    border: solid #E50012 1px;

    .pdf_txt{
      color: #E50012;
      text-align: left;
      margin-bottom: 5px;
    }

    .pdf_ttl{
      color: #E50012;
    }

    .pdf_link{
      padding-bottom: 0;
       text-align: center;
    }
  }
}

.u-tour_link{
  .tour_link_item{
    position: relative;
    background-image: linear-gradient(to right, black 20%, rgba(0,0,0,0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
    color: #fff;
    &:first-child::before{
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      position: relative;
      background-image: linear-gradient(to right, black 20%, rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
      color: #fff;
    }
    a{
      display: block;
      font-size: .9rem;
      padding: 1em 0;
    }
  }
}

.u-tourview{
  // padding: 0 20px;
  .tour_img{
    width: 100%;
    height: auto;
  }
}

/*タブ切り替え全体のスタイル*/
.u-tab {
  width: 100%;
  margin: 30px 0;
  padding: 40px 0;
  background-color: #fff;

  .tab_item {
    display: block;
    float: left;
    width: calc((100% / 5) - 12px);
    height: 25px;
    margin: 5px 0;
    padding: 0 5px;
    line-height: 25px;
    text-align: center;
    text-align: center;
    transition: all 0.2s ease;

    @include mq(xs) {
      font-size: .8em;
    }

    &:not(:last-of-type) {
      // border-right: 2px solid black;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  input:checked + .tab_item {
    background-color: #1DBAA6;
    color: white;
    opacity: 1;
  }

  input[name="tab_item"] {
    display: none;
  }

  .tab_content {
    display: none;
    border-top: 2px solid black;
    padding: 30px 10px;
    clear: both;

    .tab_txt{
      p{
        margin-bottom: 2em;
      }

      .ind{
        margin-left: 1.5em;
      }

      .line{
        margin-bottom: 20px;
        border-bottom: solid 1px black;
      }

      .cent{
        text-align: center;
      }

      .map_01{
        max-width: 500px;
        width: 100%;
        height: auto;
      }

      .map_02{
        max-width:370px;
        width: 100%;
        height: auto;
      }

      .outline{
        display: inline-block;
        padding: 2px 10px;
        border: solid 2px black;
        border-radius: 15px;

        &.red{
          border: solid 2px #E50012;
          color: #E50012;
        }
      }

      .point{
        padding-left: .5em;
        font-size: .9rem;

        &.red{
          color: #E50012;
        }
      }

      h4{
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
      }

      h5{
        font-size: 1rem;
        margin-bottom: 5px;
      }

      li{
        margin: 5px 0;
        font-weight: bold;
      }

      .txt_red{
        color: #E50012;
      }

    }

    .access_link{
      padding: 20px 30px;
      background: #eee;
      .access_ttl{
        font-weight: bold;
        padding-bottom: 10px;
      }
      .access_txt{
        margin-left: 1em;
        a{
          text-decoration: underline;
        }
      }
    }

    .tab_info{
      display: flex;
      align-items: flex-start;
      padding-bottom: 20px;

      > dt{
        display: flex;
        align-items: center;
        width: 10em;
        font-size: 1rem;

        i{
          margin-right: 10px;
        }

        &.no-icon{
          text-indent: 34px;
        }
      }
      > dd{
        width: 100%;
        font-weight: bold;
        > p{
          margin-bottom: 5px;
        }
        .half_ind{
          text-indent: -0.5em;
        }
      }

      &.column{
        flex-direction: column;

        > dt{
          width: 100%;
          padding-bottom: 20px;
        }

        .gray{
          width: calc(100% - 40px);
          max-width: 500px;
          padding: 20px;
          background: rgba(0,0,0,.03);
          font-size: 1.1rem;
        }

      }
    }
    .belongings_book{
      background: white;
      padding: 30px;
      box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
      .belongings_ttl{
        max-width: 500px;
        width: 100%;
        margin: auto;
        img{
          width: 100%;
          height: auto;
        }
        @include mq(xs) {
          max-width: 354px;
          width: 100%;
          margin: auto;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
      .belongings_sub{
        text-align: center;
        padding-top: 5px;
      }
      .contents_ttl{
        font-size: 1.2rem;
        text-decoration: underline;
        padding-bottom: 8px;
      }
      .contents_group{
        position: relative;
        display: flex;
        justify-content: space-around;
        padding-bottom: 10px;
        @include mq(xs) {
          flex-direction: column;
          align-items: center;
        }
      }
      .contents_read{
        flex: 1;
        .attention{
          color: #EC1B23;
        }
      }
      .contents_img{
        height: 100%;
        padding-left: 20px;
        padding-bottom: 10px;
        &-kuma{
          position: absolute;
          height: auto;
          right: 0;
          bottom: -15px;
          z-index: 2;
        }
      }
      .contents_list{
        padding-bottom: 20px;
        min-width: 300px;
        >dt{
          display: flex;
          padding-bottom: 10px;
          >i{
            margin-right: 10px;
          }
        }
        >dd{
          text-indent: -1em;
          padding-left: 2em;
          &:before{
            content: "□";
          }
        }
      }
    }
  }
}

.u-timesch{
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  .timesch_item{
    width: calc((100% / 3) - 20px);
    padding: 0 10px 20px 10px;

    @include mq(xs) {
      width: calc((100% / 2) - 20px);
    }

    @include mq(xxs) {
      width: 90%;
    }
  }
  .timesch_img{
    position: relative;
    border-radius: 5px;
    margin-bottom: 10px;
    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 5px;
      object-fit: cover;
      object-position: center;
      font-family: 'object-fit: cover; object-position: bottom;';
    }
  }
  .timesch_ttl{
    position: relative;
    min-height: 50px;
    margin: 10px 0;
    padding-bottom: .5em;
    background-image: linear-gradient(to right, black 20%, rgba(0,0,0,0) 0%);
    background-position: bottom;
    background-size: 8px 2px;
    background-repeat: repeat-x;
    font-size: 1rem;

    &:first-child::before{
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      margin-bottom: .5em;
      position: relative;
      background-image: linear-gradient(to right, black 20%, rgba(0,0,0,0) 0%);
      background-position: bottom;
      background-size: 8px 2px;
      background-repeat: repeat-x;
      color: #fff;
    }

    span{
      display: block;
      font-size: .8rem;
      text-align: right;
    }
  }
  .timesch_txt{
    font-size: .75rem;
  }
}

.u_lincle_rule{
  background: white;
  padding: 30px;
  border: solid 4px black;
  // box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  .lincle_rule_ttl{
    height: 40px;
    width: auto;
    padding-bottom: 30px;
    text-align: center;
    img{
      height: 100%;
      width: auto;
    }
  }
  .lincle_rule_sub{
    font-size: .9rem;
  }
  .rule_cat{
    display: flex;
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 30px;
    i{
      margin-right: 10px;
    }
  }
  .rule_list{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    .rule_item{
      width: 31%;
      height: 100%;
      &-last{
        width: 60%;
        height: 100%;
      }
    }
  }
  @include mq(xs) {
    .rule_list{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
      .rule_item{
        width: 100%;
        max-width: 300px;
        height: auto;
        padding-top: 20px;
        &-last{
          width: 130%;
          max-width: 600px;
          height: auto;
          padding-top: 20px;
        }
      }
    }
  }
}


#course:checked ~ #course_content,
#price:checked ~ #price_content,
#participation:checked ~ #participation_content,
#belongings:checked ~ #belongings_content,
#access:checked ~ #access_content {
  display: block;
}

.u-message{
  max-width: 500px;
  width: 100%;
  height: auto;
  margin: 30px auto 100px auto;
  background: url( "../images/bg_logo.png" ) center center / 100% auto no-repeat ;
  text-align: center;

  > p{
    line-height: 2em;
    > span{
      display: block;
    }
  }
}

.u-stafflist{
  .stafflist_ttl{
    margin-bottom: 70px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
}

.u-staff{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: 50px;
  .staff_item{
    width: calc((100% / 3) - 50px);
    padding: 0 25px 20px 25px;

    @include mq(xs) {
      width: calc((100% / 2) - 50px);
    }

    @include mq(xxs) {
      width: 90%;
    }
  }
  .staff_img{
    position: relative;
    border-radius: 50%;
    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }
    img{
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      // border-radius: 50%;
      // object-fit: cover;
      // object-position: center;
      // font-family: 'object-fit: cover; object-position: bottom;';
    }
  }
  .staff_name{
    position: relative;
    margin-bottom: 5px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
  .staff_sub{
    position: relative;
    margin-bottom: 20px;
    font-size: .9rem;
    text-align: center;
  }
  .staff_txt{
    padding: 0 20px;
    margin-bottom: 30px;
    font-size: .85rem;
  }
}

.u-form{
  // width: 100%;
  max-width: 700px;
  margin: 20px auto;
  padding: 10px;
  padding-bottom: 150px;
  // background: white;
  .form_table{
    width: calc(100% - 20px);
  }
  .form_item{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 16px 0;

    &.row{
      flex-direction: row;
      justify-content: center;
    }

    .item_ttl{
      margin-bottom: .5em;
    }
    button{
      margin: 10px;
    }

  }
  a.link{
    color: #1DBAA6　!important;
    text-decoration: underline;
  }

  p.error_messe{
    margin-top: 20px;
  }

  .center{
    text-align: center;
  }
  .green{
    color: #1DBAA6;
  }
}
.formTable{
  width: 100%;
  margin: 40px 0 20px 0;
  th{
    width: 9em;
    padding: 10px 0;
    color: gray;
  }
}

.u-footer{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 300px;
  max-width: 650px;
  margin: auto;
  @include mq(lg) {
    height: 300px;
  }

  .u-sns_action{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: 210px;
    width: 190px;
  }

  .footer_info{
    display: flex;
    align-items: center;
    .logo{
      width: 110px;
      height: 30px;
      padding: 10px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .info_txt{
      > dt{
        font-size: 0.75em;
      }
      > dd{
        font-size: 0.6em;
        line-height: 1;
      }
    }
  }

  .copy{
    margin-bottom: 10px;
    font-size: 0.7em;
    color: white;
    letter-spacing: 0.1em;
    text-align: center;
  }
}
