@charset "utf-8";

//************************************
// input,texteria files
//************************************

input[type=text], button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=text],textarea{
  width: 100%;
  margin: auto;
  padding: 10px;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  color: #777;
  border: solid 1px #ccc;

  border-radius: 3px;
  box-shadow: inner 0 0 4px rgba(0, 0, 0, 0.2);
  &:focus {
    border:solid 1px #1DBAA6;
  }
}

textarea{
  resize: vertical;
  min-height: 6em;
}

%btn{
  height: 40px;
  min-width: 100px;
  padding: 10px;
  font-size: .8rem;
}

.btn_submit{
  @extend %btn;
  border:solid 1px #1DBAA6;
  color: #1DBAA6;
  &:hover{
    background: #1DBAA6;
    color: white;
  }
}

.btn_reset{
  @extend %btn;
  border:solid 1px #aaa;
  color: #aaa;
  &:hover{
    background: #aaa;
    color: white;
  }
}
