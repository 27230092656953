@charset "utf-8";

//************************************
// setting files
//************************************

//== Screen Size
$screen-xs: 480px !default; //Extra small screen(xs)->smartphone
$screen-sm: 768px !default; //Small screen(sm) ->tablet
$screen-md: 992px !default; //Medium screen(md) ->desktop
$screen-lg: 1200px !default; //Large screen(lg） ->wide desktop

@mixin mq($DEVICE) {
    // Large screen / wide desktop
    @if $DEVICE == "lg" {
        @media (min-width: $screen-lg) {
            @content;
        }
    }
    // Medium screen / desktop
    @else if $DEVICE == "md" {
        @media (min-width: $screen-md) and (max-width: ($screen-lg - 1)) {
            @content;
        }
    }
    // Small screen / tablet
    @else if $DEVICE == "sm" {
        @media (min-width: $screen-sm) and (max-width: ($screen-md - 1)) {
            @content;
        }
    }
    // Super Extra small screen / smartphone
    @else if $DEVICE == "xxs" {
        @media (max-width: ($screen-xs - 1)) {
            @content;
        }
    }
    // Extra small screen / smartphone
    @else if $DEVICE == "xs" {
        @media (max-width: ($screen-sm - 1)) {
            @content;
        }
    }
    // ★PC★ (md & lg)
    @else if $DEVICE == "pc" {
        @media (min-width: $screen-md) {
            @content;
        }
    }
    // ★SP★ (xs & sm)
    @else if $DEVICE == "sp" {
        @media (max-width: ($screen-md - 1)) {
            @content;
        }
    }
}


//************************************
// base files
//************************************

body{
  width: 100%;
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  line-height: 1.4;

  @include mq(xs) {
    font-size: 15px;
  }
}

a{
  text-decoration:none;
  &:link {
	color: #000;
  }

  &:visited {
  	color: #000;
  }

  &:hover {
    color: #777;
  }

  &:active {
  	color: #777;
  }
}
